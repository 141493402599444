/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useMediaQuery, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import { ExpandMore } from '@material-ui/icons';
import ReactHTMLParser from 'react-html-parser';
import {
    arrayOf, func, shape, string, node, object,
} from 'prop-types';
import { connect } from 'react-redux';
import ZipInputField from '../Partials/ZipInputField';
import { getSSRDeviceType } from '../../../../../../../state/ducks/App/App-Selectors';

const useStyles = makeStyles((theme) => ({
    header: (css) => ({
        margin: 0,
        fontFamily: css?.style?.fonts || 'Crimson Italic',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '24px',
        lineHeight: '32px',
        color: css?.style?.fontColor  || theme.palette.black || '#000000',
    }),
    headingMobile: {
        margin: '0 auto',
        textAlign: 'center',
    },
    iconMobile: {
        float: 'left',
        '& img': {
            width: '45px',
            height: '45px',
            borderRadius: '50%',
        },
    },
    inputLabel: (css) => ({
        fontFamily: css?.style?.fonts || 'Crimson Italic',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '19px',
        lineHeight: '24px',
        '@media screen and (min-width: 960px) and (max-width: 1220px)': {
            fontSize: '16px',
        },
        color: css?.style?.fontColor || theme.palette.black || '#222222',
    }),
    inputLabelMobile: {
        fontSize: '16px !important',
        textAlign: 'left',
        '@media screen and (max-width: 380px)': {
            fontSize: '13px !important',
        },
    },
    img: {
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        overflow: 'hidden',
        '& img': {
            maxWidth: '100%',
        },

    },
    section: (css) =>  ({
        width: '100%',
        marginBottom: css?.style?.marginBottom || '30px',
        minHeight: '114px',
        background: css?.style?.boxBackground || '#F4F0E3',
    }),
    sectionMobile: (css) => ({
        marginBottom: css?.style?.marginBottom || '30px',
        width: '100%',
    }),
    containerMobile: (css) => ({
        background: css?.style?.boxBackground || '#F4F0E3',
        maxWidth: css?.style?.maxWidth,
        padding: '20px 12px',
        margin: '0 auto',
        width: '90%',
        borderRadius: '8px',
    }),
    container: (css) => ({
        maxWidth: css?.style?.boxMaxWidth || '1400px',
        margin: '0 auto',
        width: '100%',
        padding: '30px 10px 24px 10px',
    }),
    border: {
        borderBottom: '1px solid #AAAAAA',
    },
    desktopFieldBox: {
        maxWidth: '830px',
        margin: '0 auto',
    },
    lastItem: {
        width: '95%',
        borderRight: '0px!important',
    },
    formBox: {
        width: '90%',
        '@media screen and (max-width: 380px)': {
            width: '95%',
        },
    },
    finderIcon: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        '@media screen and (min-width: 767px) and (max-width: 959px)': {
            alignItems: 'flex-end',
        },
    },
    button: (css) => ({
        fontSize: '18px',
        fontFamily: css?.style?.fonts || 'Crimson Italic',
        padding: '8px 20px 9px',
        color: `${theme.palette.white || theme.palette.colorPrimary}`,
        backgroundColor: theme.palette.cms?.primary,

    }),

    buttonBoxMobile: () => ({
        padding: '5px 0px !important',
        height: '45px !important',

    }),
    buttonBox: (css) => ({
        backgroundColor: `${css?.style?.buttonBackground || theme.palette?.primary?.main || '#2f2f2f'}`,
        textDecoration: 'none',
        textAlign: 'center',
        padding: '14px 0px',
        position: 'relative',
        zIndex: '1',
        width: '139px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '32px',
        color: css?.style?.buttonTextColor || theme.palette.white || '#F9F8F2',
        '& button': {
            border: 'none',
            background: 'transparent',
            color: `${css?.style?.buttonTextColor || theme.palette.white || '#ffffff'}`,
            width: '100%',
            padding: 0,
        },
        '&:after, &:before': {
            width: '100%',
            height: '19px',
            margin: 'auto',
            content: "''",
            display: 'block',
            position: 'absolute',
            background: css?.style?.boxBackground || '#F3F0E2',
            borderRadius: '22%',
        },
        '&:before': {
            top: '-17px',
            left: 0,
            right: 0,
        },
        '&:after': {
            bottom: '-17px',
            left: 0,
            right: 0,
        },
    }),

    select: (css) => ({
        fontSize: '28px',
        fontFamily: css?.style?.fonts || 'Crimson Italic',
        '& svg': {
            color: css?.style?.fontColor || '#000',
        },
        '& select': {
            width: '100%',
            background: 'transparent !important',
            padding: '5px 12px 5px 0px',
            '& :focus': {
                background: 'transparent',
            },
        },
        '&.Mui-focused': {
            background: 'transparent',
        },
    }),
    selectMobile: {
        fontSize: '20px !important',
    },
    field: {
        textAlign: 'center',
        borderRight: '1px solid #AAAAAA',
    },
    fieldMobile: {
        textAlign: 'center',
        borderRight: '1px solid #AAAAAA',
        width: '100%',
    },
    borderMobile: {
        borderBottom: '1px solid #AAAAAA',
    },
    headingName: {
        '& p': {
            margin: 0,
        },
    },
    buttonMobileBox: {
        marginTop: '20px',
    },
    headingBoxSection: {
        '@media screen and (min-width: 767px) and (max-width: 959px)': {
            marginBottom: '15px',
        },
    },
    headingSection: {
        '@media screen and (min-width: 767px) and (max-width: 959px)': {
            paddingLeft: '5px',
        },
        display: 'flex',
        alignItems: 'center',
    },
    buttonBoxContainer: {
        justifyContent: 'flex-end',
        '@media screen and (min-width: 767px) and (max-width: 959px)': {
            justifyContent: 'center',
            marginTop: '20px',
        },
    },
}));

const GiftFinder = ({
    heading, icon, submission, fields, container, device,
}) => {
    const classes = useStyles(container || {});
    const isMobile = useMediaQuery('(max-width: 767px)') || device === 'mobile';
    const isTab = useMediaQuery('(max-width: 959px)');

    const defaultIcon = (
        <svg
            viewBox="0 0 27 27"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
            style={{
                pointerEvents: 'none',
                display: 'block',
            }}
        >
            <g>
                <path
                    fill="#000"
                    d="M22,12V20A2,2 0 0,1 20,22H4A2,2 0 0,1 2,20V12A1,1 0 0,1 1,11V8A2,2 0 0,1 3,6H6.17C6.06,5.69 6,5.35 6,5A3,3 0 0,1 9,2C10,2 10.88,2.5 11.43,3.24V3.23L12,4L12.57,3.23V3.24C13.12,2.5 14,2 15,2A3,3 0 0,1 18,5C18,5.35 17.94,5.69 17.83,6H21A2,2 0 0,1 23,8V11A1,1 0 0,1 22,12M4,20H11V12H4V20M20,20V12H13V20H20M9,4A1,1 0 0,0 8,5A1,1 0 0,0 9,6A1,1 0 0,0 10,5A1,1 0 0,0 9,4M15,4A1,1 0 0,0 14,5A1,1 0 0,0 15,6A1,1 0 0,0 16,5A1,1 0 0,0 15,4M3,8V10H11V8H3M13,8V10H21V8H13Z"
                />
            </g>
        </svg>
    );
    const selectField = (field) => {
        const {
            value, inputProps, handleChange, selectedValue, label,
        } = field;
        return (
            <FormControl className={`${classes.formBox}`} role="region" aria-label={inputProps.id}>
                <InputLabel className={`${classes.inputLabel} ${isMobile ? classes.inputLabelMobile : ''}`} htmlFor={inputProps.id}>{label}</InputLabel>
                <NativeSelect
                    defaultValue=""
                    IconComponent={ExpandMore}
                    className={`${classes.select} ${isMobile ? classes.selectMobile : ''}`}
                    value={selectedValue}
                    disableUnderline
                    onChange={(e) => handleChange(e)}
                    inputProps={inputProps}
                >
                    <option aria-label="None" aria-hidden="true" value="" />
                    {
                        value?.map((k) => <option aria-hidden="true" value={k.value}>{k.key}</option>)
                    }
                </NativeSelect>
            </FormControl>
        );
    };

    const zipField = (field) => {
        const {
            handleChange, selectedValue, label, config,
        } = field;
        return (
            <ZipInputField
                zipLabel={label}
                currentInputLength={5}
                handleChange={handleChange}
                onSubmitCheck={config?.onSubmitCheck}
                zipcodeIsValid={config?.zipcodeIsValid}
                userSubmittedZip={config?.userSubmittedZip}
                value={selectedValue}
                giftFinderBodyTextColor={config?.giftFinderBodyTextColor}
                giftFinderView
                giftFinderFieldStyle={classes}
                isMobile={isMobile}
            />
        );
    };

    const renderFields = () => (
        <Grid className={classes.desktopFieldBox} container>
            {
                fields?.map((field, index) => {
                    const {
                        style, type,
                    } = field;
                    return (
                        (
                            <Grid item xs={4}>
                                <div className={classes.border}>
                                    <div
                                        style={{
                                            textAlign: style?.align || 'left',
                                        }}
                                        className={`${classes.field} ${index === (fields.length - 1) ? classes.lastItem : ''}`}
                                    >
                                        {type === 'select' ? selectField(field) : <></>}
                                        {type === 'zip' ? zipField(field) : <></>}
                                    </div>
                                </div>
                            </Grid>
                        )
                    );
                })
            }
        </Grid>
    );

    const renderFieldsMobile = () => (
        <>
            {
                fields?.map((field, index) => {
                    const {
                        style, type,
                    } = field;
                    return (
                        (
                            <Grid item xs={6}>
                                <div className={`${index === (fields.length - 1) ? classes.lastItemMobile : classes.borderMobile}`}>
                                    <div
                                        style={{
                                            textAlign: style?.mobileAlignLeft,
                                            borderRightWidth: style?.mobileRightBorder ? '1px' : '0px',
                                            marginTop: style?.marginTop,
                                        }}
                                        className={`${classes.fieldMobile}`}
                                    >
                                        { type === 'select' ? selectField(field) : <></>}
                                        {type === 'zip' ? zipField(field) : <></>}
                                    </div>
                                </div>
                            </Grid>
                        )
                    );
                })
            }
        </>
    );

    if (isMobile) {
        return (
            <>
                <div className={classes.sectionMobile}>
                    <div className={classes.containerMobile}>
                        <Grid container>
                            <Grid item xs={12}>
                                <p className={classes.headingMobile}>
                                    <div className={classes.iconMobile}>
                                        {icon ? <img src={icon} alt={heading} /> : defaultIcon}

                                    </div>
                                    <h3 className={classes.header}>{ReactHTMLParser(heading?.replace?.('\\n', ''))}</h3>
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className={classes.desktopFieldBox} container>
                                    {renderFieldsMobile()}
                                    <Grid className={classes.buttonMobileBox} item xs={6}>
                                        <Grid container justify="flex-end">
                                            <span className={`${classes.buttonBox} ${classes.buttonBoxMobile}`}>
                                                <ButtonBase
                                                    className={`${classes.button}`}
                                                    onClick={() => submission?.action()}
                                                >
                                                    {submission.label}
                                                </ButtonBase>
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className={classes.section}>
                <div className={classes.container}>
                    <Grid container>
                        <Grid item sm={12} lg={3} md={3} xl={3}>
                            <Grid container className={classes.headingBoxSection}>
                                <Grid className={classes.finderIcon} item xs={4} md={4} xl={4} lg={4} sm={5}>
                                    <div className={classes.img}>
                                        {icon ? <img src={icon} alt={heading} /> : defaultIcon}
                                    </div>
                                </Grid>
                                <Grid item className={classes.headingSection} lg={7} md={7} xl={7} sm={7}>
                                    <h3 className={classes.header}>{
                                        isTab ? ReactHTMLParser(heading?.replace?.('\\n', '')) : ReactHTMLParser(heading?.replace?.('\\n', '<br/>'))
                                    }
                                    </h3>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid sm={12} item lg={7} md={7} xl={7}>
                            {renderFields()}
                        </Grid>
                        <Grid item sm={12} lg={2} md={2} xl={2}>
                            <Grid item xs={12}>
                                <Grid container className={classes.buttonBoxContainer}>
                                    <span className={classes.buttonBox}>
                                        <ButtonBase
                                            className={`${classes.button}`}
                                            onClick={(e) => submission?.action(e)}
                                        >
                                            {submission.label}
                                        </ButtonBase>
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

GiftFinder.propTypes = {
    heading: node,
    icon: string,
    submission: shape({
        label: string,
        action: func,
    }),
    fields: shape({
        label: string,
        value: arrayOf(
            shape({
                key: string,
                value: string,
            }),
        ),
        name: string,
        type: string,
        handleChange: func,
    }),
    container: shape({
        style: object,
    }),
    device: string,
};

GiftFinder.defaultProps = {
    container: {},
    heading: '',
    icon: '',
    device: '',
    submission: {
        label: '',
        action: () => {},
    },
    fields: {
        label: '',
        value: [],
        name: '',
        type: '',
        handleChange: () => {},
    },
};

const mapStateToProps = (state) => ({
    device: getSSRDeviceType(state),
});

export default connect(
    mapStateToProps,
)(GiftFinder);
