/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { uid } from 'react-uid';
import { withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators, compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import {
    object, shape, array, string, func, bool,
} from 'prop-types';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { FormHelperText, useMediaQuery } from '@material-ui/core';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import useUIDQueryConditionally from '../../../../../helpers/hooks/useUIDQueryConditionally';
import DesktopMobile from '../../../../../helpers/DesktopMobile/DesktopMobile';
import GenericSkeleton from '../../../GraphqlSkeletonComponents/GenericSkeleton';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import GiftFinder from './v2/GiftFinder';
import { getActiveABTests } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import GiftFinderV2Presentation from './v2/GiftFinderV2Presentation';
import { emitCustomTrackingEvent } from '../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { getIsAuthenticatedStatus } from '../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getProductFilterProfile } from '../../../../../../state/ducks/App/App-Selectors';
import GiftFinderProductContainer from '../../../GiftFinderProduct/GiftFinderProductContainer';

const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const useStyles = makeStyles((theme) => ({
    giftFinderGFB: {
        boxSizing: 'border-box',
        padding: '55px 0px',
        margin: '30px auto',
        maxWidth: '1400px',
        minHeight: '400px',
        width: '100%',
        textAlign: 'center',
        height: 'auto',
        '& .MuiFormLabel-filled + .MuiInput-formControl': {
            borderColor: theme.palette.giftFinderSelectColor,
        },
        '@media screen and (max-width: 1024px)': {
            maxWidth: '100%',
        },
        '& h2': {
            margin: 0,
            fontSize: '2em',
            lineHeight: '2em',
            fontWeight: '400',
            '@media screen and (max-width: 601px)': {
                fontSize: '1.3em',
                lineHeight: '1em',
                fontWeight: '400',
            },
        },
        '@media screen and (max-width: 600px)': {
            '& $fieldBlock': {
                marginBottom: '24px',
            },
        },
        '@media screen and (max-width: 376)': {
            '& $fieldBlock': {
                marginBottom: '20px',
            },
        },
        '@media screen and (max-width: 375px)': {
            '& $paperBody': {
                minHeight: '340px',
                maxWidth: '295px',
            },
            '& $headingImg': {
                minHeight: '18px',
                maxWidth: '20px',
            },
            '& $headingCopy': {
                fontSize: '20px',
            },
            '& $heading': {
                padding: '16px 0px 21px',
            },
            '& $select , $button': {
                minHeight: '40px',
                maxWidth: '220px',

            },
            '& $button': {
                margin: '-4px auto 0',
                width: '100%',
            },
            '& $fieldBlock': {
                marginBottom: '24px',
            },
            '& .MuiInputLabel-outlined': {
                transform: 'translate(14px, 15px) scale(1)',
            },
            '& [class*="shrink"]': {
                top: '6px',
                transform: 'translate(12px, -8px) scale(0.75)',
            },
            padding: '30px 0px',
        },
        '@media screen and (min-width: 375px) and (max-width: 414px)': {
            '& $select': {
                minHeight: '44px',
                maxWidth: '270px',
            },
        },
        '@media screen and (min-width: 376px) and (max-width: 414px)': {
            '& $paperBody': {
                minHeight: '361px',
                maxWidth: '350px',
            },
            '& $headingImg': {
                minHeight: '24px',
                maxWidth: '24px',
            },
            '& $heading': {
                padding: '12px 0px 18px',
            },
            '& $headingCopy': {
                fontSize: '24px',
            },
            '& $select': {
                minHeight: '44px',
                maxWidth: '286px',
            },
            '& $fieldBlock': {
                marginBottom: '26px',
            },
            '& $button': {
                minHeight: '48px',
                maxWidth: '286px',
                margin: '0px auto',
                width: '100%',
            },
            padding: '18px 0px',
        },
        '@media screen and (min-width: 415px) and (max-width: 1023px)': {
            '& $paperBody': {
                minHeight: '364px',
                maxWidth: '522px',
            },
            '& $headingImg': {
                minHeight: '24px',
                maxWidth: '24px',
            },
            '& $heading': {
                padding: '22px 0px 24px',
            },
            '& $select': {
                minHeight: '52px',
                maxWidth: '347px',
            },
            '& $headingCopy': {
                fontSize: '24px',
                lineHeight: '1em',
            },
            '& $fieldBlock': {
                marginBottom: '20px',
            },
            '& $button': {
                minHeight: '48px',
                maxWidth: '192px',
                width: '100%',
                margin: '0px auto 0',
            },
            padding: '18px 0px',
        },
        '@media screen and (min-width: 1024px) and (max-width:1439px)': {
            '& $paperBody': {
                minHeight: '268px',
                maxWidth: '854px',
            },
            '& $heading': {
                padding: '28px 0px 27px',
            },
            '& $select': {
                minHeight: '50px',
                maxWidth: '245px',
            },
            '& $fieldBlock': {
                maxWidth: '245px',
            },
            '& $button': {
                margin: '20px auto 0',
            },
            padding: '48px 0px',
        },
    },
    giftFinderGFBWithGrid: {
        margin: 'auto',
    },
    lcpImage: {
        display: 'none',
        // does not display but is fetched earlier in the page process/render, improving LCP
    },
    giftFinderGFBBG: {
        background: theme.palette.cms?.giftFinderBackgroundColor || '#F2F2F2',
    },

    container: {
        width: '100%',
        margin: '0 auto',
        '@media screen and (max-width: 601px)': {
            padding: '25px 39px',
        },
        '@media screen and (max-width: 375px)': {
            padding: '25px 15px',
        },
        '& .MuiGrid-item': {
            '@media screen and (max-width: 601px)': {
                padding: 0,
            },
        },
        '& .MuiGrid-container': {
            '@media screen and (max-width: 601px)': {
                marginTop: 0,
                width: '100%',
                margin: 0,
            },
        },
    },
    fieldBlock: {
        maxWidth: '315px',
        margin: '0 8px 0',
        position: 'relative',
        '& label': {
            fontSize: '16px',
        },
        '& [class*="shrink"]': {
            top: '12px',
            color: theme.palette.giftFinderSelectColor,
        },
        '& .MuiInput-select:focused': {
            background: theme.palette.myAccount?.container?.backgroundColor,
        },
        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },
    },
    select: ({ data }) => ({
        width: '315px',
        minHeight: '52px',
        border: `solid 1px ${theme.palette.colorNeutral70}`,
        padding: '2px 12px 2px 0px',
        background: theme.palette.tiers?.card?.backgroundColor,
        fontSize: '1.2em',
        fontFamily: parseFont(data?.reference?.[0]?.select_field_font),
        '& :focus': {
            borderRadius: 3,
            background: theme.palette.myAccount?.container?.backgroundColor,
        },
        '& .MuiNativeSelect-select': {
            padding: '7px 12px',
            color: theme.palette.giftFinderSelectColor,
            [theme.breakpoints.down(600)]: {
                padding: '8px 13px',
                margin: '6px 0px 0px',
                fontSize: '18px',
            },
            '@media screen and (max-width: 375px)': {
                fontSize: '0.938rem',
            },
        },
        '& .MuiNativeSelect-filled': {
            border: `solid 1px ${theme.palette.giftFinderSelectColor} `,
        },
        '& svg': {
            fill: theme.palette.colorNeutral70,
        },
    }),
    button: {
        margin: '24px auto 0',
        borderRadius: '28px',
        backgroundColor: theme.palette.primaryButton,
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: '700',
        '&:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
        },
        '@media screen and (min-width: 601px)': {
            minWidth: 192,
            minHeight: 48,
        },
        [theme.breakpoints.down('md')]: {
            '&:hover': {
                color: theme.palette.white,
                backgroundColor: theme.palette.primaryButton,
            },
        },
    },
    heading: {
        margin: '0 auto',
        display: 'flex',
        width: '50%', // IE
        // eslint-disable-next-line no-dupe-keys
        width: 'fit-content',
        padding: '24px 0px 30px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headingCopy: ({ data }) => ({
        fontSize: '2em',
        fontFamily: parseFont(data?.reference?.[0]?.heading_font),
    }),
    headingImg: {
        position: 'relative',
        display: 'inline-block',
        maxWidth: '28px',
        minHeight: '28px',
        margin: '0 12px 0 0',
        '& img': {
            width: '100%',
            height: '100%',
        },
    },
    bgImageStyle: {
    },
    paperBody: {
        borderRadius: 8,
        minHeight: '290px',
        maxWidth: '1184px',
        margin: '0 auto',

    },
    helperText: {
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        fontSize: '14px',
        overflowY: 'hidden',
        maxHeight: '0',
        top: '90%',
        transitionProperty: 'all',
        transitionDuration: '.5s',
        transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',

        '@media screen and (max-width: 601px)': {
            top: '100%',
            fontSize: '12px',
            margin: 0,
        },
        '& svg': {
            fontSize: '14px',
            borderRadius: '50%',
            color: theme.palette.giftFinderErrorColor,
            backgroundColor: theme.palette.white,
            marginRight: '5px',
        },
    },
    findItButton: {
        '& .MuiButtonBase-root': {
            display: 'block',
            '@media screen and (max-width: 376px)': {
                margin: '0 auto',
                width: '90%',
                marginBottom: '10px',
            },
        },
    },
    errorMsg: {
        '& $helperText': {
            color: theme.palette.giftFinderErrorColor,
            maxHeight: '25px',

        },
        '& $select': {
            color: theme.palette.giftFinderErrorColor,
            borderColor: theme.palette.giftFinderErrorColor,
            '& svg': {
                fill: theme.palette.giftFinderErrorColor,
            },
        },
        '& .MuiInputLabel-formControl': {
            color: theme.palette.giftFinderErrorColor,
        },

    },
    elementHidden: {
        display: 'none',
    },
    recipientSelect: {
        '& .MuiNativeSelect-select': {
            padding: '16px 12px 0px !important',
            '@media screen and (max-width: 1024px)': {
                paddingTop: '13px !important',
            },
        },
    },
    giftFinderHorizontal: {
        margin: '0 auto',
        justifyContent: 'center',
        display: 'flex',
        maxWidth: '1400px',
        paddingBottom: '24px',
        '@media screen and (max-width: 767px)': {
            padding: '0px 24px 30px',
            margin: '0px',
        },
    },
    giftFastLineContainer: {
        marginBottom: '30px',
        '& $giftFinderGFB': {
            marginBottom: '0px',
        },
    },
    giftFastLine: {
        '& $giftFinderHorizontal': {
            maxWidth: '100%',
        },
        marginBottom: '30px',
        '@media screen and (max-width: 767px)': {
            '& $giftFinderHorizontal': {
                maxWidth: '90%',
                margin: '0 auto',
            },
        },
    },
}));

function GraphqlFoodGiftFinder(props) {
    const {
        data: blockData, history, track, giftFinderV2FlagEnabled, abTest,
    } = props;
    const dispatch = useDispatch();
    const {
        isUniversalLander, ulGiftFinderCss, ffIsGFSuggestionsEnabled, flags,
    } = props;
    const { data, loading } = useUIDQueryConditionally({ data: blockData });
    const isAggregatorFacetErrorResolver = useSelector(getFeatureFlag('is-facet-throwing-error-resolver-enabled'));
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const selectedProfileInfo = useSelector(getProductFilterProfile);
    let delimiter = '&';
    if (isAggregatorFacetErrorResolver) {
        delimiter = '&facet=';
    }
    const blocks = data?.reference?.[0]?.styles.filter((item) => item.background_image) || [];

    const classes = useStyles({ data });
    const [state, setState] = React.useState({
        occasion: '',
        recipient: '',
        price: '',
        occasionKey: '',
        recipientkey: '',
        priceKey: '',
        disabled: 'disabled',
    });
    const [errorMessage, setErrorMessage] = React.useState({
        ccasionError: false,
        recipientError: false,
        priceError: false,
    });
    const [values, setValues]  = React.useState([]);    const handleChange = (event, keyName) => {
        const {
            options, selectedIndex, value, name,
        } = event.target;
        const inputLabel = options[selectedIndex].innerHTML.toLowerCase();
        setState({
            ...state,
            [name]: value,
            [keyName]: inputLabel?.replace(/\s/g, '').split('$').join(''), // this regex replacing all the white space  and dollar sign inside a string
        });
        setErrorMessage({
            ...errorMessage,
            [`${name}Error`]: !value,
        });
        try {
            const selectedValues = values;
            const selectedSplitValue = value.split(delimiter);
            const index = selectedValues.findIndex((check) => check === selectedSplitValue);
            if (index > -1) {
                selectedValues.splice(0, index);
            } else {
                selectedValues.push(...selectedSplitValue);
            }
            setValues(selectedValues);
        } catch (error) {
            return error;
        }
        return null;
    };

    const { tracking_link_action = '', tracking_link_category = '' } = data || {};

    const trackData = () => {
        const eventCategory = data?.a_b_testing?.submission_tacking_event_category || data?.a_b_testing?.tracking_event_category || tracking_link_category || '';
        const eventAction = data?.a_b_testing?.submission_tacking_event_action || data?.a_b_testing?.tracking_event_action || tracking_link_action || '';
        const eventLabel = `${state.recipientkey}|${state.occasionKey}|${state.priceKey}`;
        track({
            eventCategory,
            eventAction,
            eventLabel,
        });
    };
    const emitCustomClickstreamEvent = () => {
        const { name, relationshipName: selectedRelationShip = '' } = selectedProfileInfo;
        const occasionAfterSplit = state?.occasion?.split('|')[1].replaceAll('_', ' ');
        const recipient = state?.recipient?.split('|')[1] || '';
        const customTrackingSchema = {
            action: 'customer-input', relationship: selectedRelationShip || recipient, occasionCode: occasionAfterSplit, page: { type: 'home' }, eventSubType: 'customer-input-find-gift-fast',
        };
        if (isAuthenticated && name) {
            const nameCollection = name.split(' ');
            customTrackingSchema.firstName = nameCollection?.[0];
            customTrackingSchema.lastName = nameCollection?.[1];
        }
        dispatch(emitCustomTrackingEvent(customTrackingSchema));
    };
    const  validation = () => {
        let occasionError = false; let recipientError = false; let priceError = false;
        let error = true;
        if (!state.occasion) {
            occasionError = true;
            error = false;
        }
        if (!state.recipient) {
            recipientError = true;
            error = false;
        }
        if (!state.price) {
            priceError = true;
            error = false;
        }
        setTimeout(() => { // hide the error message after two second
            setErrorMessage({
                occasionError: false,
                recipientError: false,
                priceError: false,
            });
        }, 2000);

        setErrorMessage({
            occasionError,
            recipientError,
            priceError,
        });
        return error;
    };
    const handleOnClick = () => {
        if (validation()) {
            trackData();
            emitCustomClickstreamEvent();
            const querystring = `?facet=${state.recipient}${delimiter}${state.occasion}${delimiter}${state.price}`;
            const path = data.reference[0].landing_page?.href ? `${data.reference[0].landing_page.href}` : '/';
            history.push({
                pathname: path,
                search: querystring,
                state: values,
            });
        }
    };
    const dataSelector = data?.reference?.[0];
    const recipients = dataSelector.facet_recipient?.recipient?.value;
    const occasions = dataSelector.facet_occasion?.occasion?.value;
    const prices = dataSelector.facet_price?.price?.value;
    const bannerStyle = {};
    const giftFinderSuggestionEnable = ffIsGFSuggestionsEnabled && flags?.whichGiftFinderSuggestion === 'variant' && dataSelector?.has_recommendations;
    const useNewDimensionsIfAvailable = (style) => {
        const unit = style.dimensions?.unit === 'Percent' ? '%' : 'px';
        if (style.dimensions?.width) {
            bannerStyle.width = `${style.dimensions?.width}${unit}`;
        }
        if (style.dimensions?.height) {
            bannerStyle.height = `${style.dimensions?.height}${unit}`;
        }
        if (style.dimensions?.max_width) {
            bannerStyle.maxWidth = style.dimensions?.max_width;
        }
        if (style.background_image?.color) {
            bannerStyle.backgroundColor = style.background_image?.color;
        }
    };

    if (data?.reference?.[0]?.styles) {
        data.reference[0].styles.forEach((styleList) => {
            const type = Object.keys(styleList)[0];
            switch (type) {
                case 'background_image':
                    useNewDimensionsIfAvailable(styleList);
                    break;
                case 'dimensions':
                    useNewDimensionsIfAvailable(styleList);
                    break;
                default:
                    return null;
            }
            return null;
        });
    }
    const errorShowing = (errorMsg) => (
        <FormHelperText
            aria-hidden="true"
            className={`${classes.helperText} `}
        >
            <CancelOutlinedIcon />{errorMsg}
        </FormHelperText>
    );
    const { occasionError, priceError, recipientError } = errorMessage;
    // TODO: add alt (right flanking image) support
    const headingImage = dataSelector.heading_image?.[0] ? <img src={dataSelector.heading_image?.[0]?.url} alt={dataSelector.heading_image?.[0]?.description || 'Gift Finder Icon'} /> : '';

    // Skeleton if defined and fetching data
    if (loading) {
        return (
            <DesktopMobile
                desktop={() => blockData?.desktop_skeleton && <GenericSkeleton height={blockData?.skeleton_height} />}
                mobile={() => blockData?.mobile_skeleton && <GenericSkeleton height={blockData?.mobile_skeleton_height} />}
            />
        );
    }

    const isGiftFinderVariant = (abTest?.gift_finder_test?.type === 'Variant') || false;
    const renderFastLineGiftFinder = (productData = []) => (
        giftFinderSuggestionEnable ? (
            <div
                className={classes.giftFinderHorizontal}
                style={{
                    backgroundColor: productData?.styling?.module_background_color?.color,
                }}
            >
                <GiftFinderProductContainer productData={productData} />
            </div>
        ) : <></>);

    if (dataSelector?.use_v2_presentation) {
        return (
            <GiftFinderV2Presentation
                headingImage={headingImage}
                dataSelector={dataSelector}
                recipientError={recipientError}
                states={state}
                handleChange={handleChange}
                recipients={recipients}
                occasionError={occasionError}
                occasions={occasions}
                handleOnClick={handleOnClick}
                priceError={priceError}
                prices={prices}
            />
        );
    }

    if (giftFinderV2FlagEnabled && isGiftFinderVariant) {
        const giftFinderV2 = dataSelector?.gift_finder_v2 || {};
        return (
            <div className={giftFinderSuggestionEnable
                ? `${classes.giftFastLine}` : ''}
            >
                <GiftFinder
                    container={{
                        style: {
                            ...bannerStyle,
                            boxBackground: giftFinderV2?.style?.box_background_color?.color,
                            fontColor: giftFinderV2?.style?.text_color?.color,
                            buttonBackground: giftFinderV2?.style?.button_background?.color,
                            buttonTextColor: giftFinderV2?.style?.button_text_color?.color,
                            boxMaxWidth: giftFinderV2?.style?.boxMaxWidth || null,
                            marginBottom: giftFinderSuggestionEnable ? '0px' : '30px',
                        },
                        fonts: parseFont(giftFinderV2?.fonts || ''),
                    }}
                    heading={giftFinderV2.heading_text}
                    icon={giftFinderV2.icon?.url}
                    submission={{
                        action: handleOnClick,
                        label: giftFinderV2?.button_text || 'Find Gift Now', // button text
                    }}
                    fields={[
                        {
                            label: 'Who\'s This For?',
                            style: {
                                align: 'left',
                                mobileAlignLeft: 'left',
                                mobileRightBorder: true,
                                marginTop: 0,
                            },
                            value: recipients,
                            selectedValue: state.recipient,
                            name: 'recipient',
                            type: 'select',
                            handleChange: ((e) => handleChange(e, 'recipientkey')),
                            inputProps: {
                                name: 'recipient',
                                id: 'recipient_select',
                            },
                        },
                        {
                            label: 'What\'s the Occasion?',
                            name: 'occasion',
                            style: {
                                align: 'center',
                                mobileAlignLeft: 'right',
                                mobileRightBorder: false,
                                marginTop: 0,
                            },
                            value: occasions,
                            selectedValue: state.occasion,
                            type: 'select',
                            handleChange: ((e) => handleChange(e, 'occasionKey')),
                            inputProps: {
                                name: 'occasion',
                                id: 'occasion_select',
                            },
                        },
                        {
                            label: 'What\'s Your Budget?',
                            name: 'price',
                            style: {
                                align: 'right',
                                mobileAlignLeft: 'left',
                                mobileRightBorder: false,
                                marginTop: 15,
                            },
                            value: prices,
                            selectedValue: state.price,
                            type: 'select',
                            handleChange: ((e) => handleChange(e, 'priceKey')),
                            inputProps: {
                                name: 'price',
                                id: 'price_select',
                            },
                        },
                    ]}
                />
                {renderFastLineGiftFinder(giftFinderV2?.product_recommendations)}
            </div>
        );
    }
    const isMobile = useMediaQuery('(max-width: 600px)');
    const imageUrl = isMobile && blocks[0]?.background_image?.mobile?.url ? `url(${blocks[0]?.background_image?.mobile?.url}?format=webp)` : `url(${blocks[0]?.background_image?.image?.url}?format=webp)`;
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    const giftFinderGFBCssStyle = `${classes.giftFinderGFB} ${ffHasHomepageGridStructure && classes.giftFinderGFBWithGrid}`;
    return ( // Render Vertical Menus
        <div className={giftFinderSuggestionEnable
            ? `${classes.giftFastLineContainer}` : ''}
        >
            <div
                className={isUniversalLander
                    ? `${giftFinderGFBCssStyle} ${ulGiftFinderCss} ${classes.giftFinderGFBBG} giftFinder`
                    : `${giftFinderGFBCssStyle} ${classes.giftFinderGFBBG} giftFinder`}
                style={{
                    backgroundImage: imageUrl,
                    backgroundSize: !blocks[0]?.background_image.tile_image && 'cover',
                    backgroundRepeat: blocks[0]?.background_image.tile_image ? 'repeat' : '',
                }}
            >
                <div className={classes.container}>
                    <Paper elevation={3} className={classes.paperBody}>
                        <div className={classes.heading}>
                            {headingImage ? <div className={classes.headingImg}>{headingImage}</div> : '' }
                            {dataSelector?.heading &&  <h2 aria-hidden="true" className={classes.headingCopy}>{dataSelector.heading}</h2>}
                        </div>
                        <Grid container justify="center">
                            <Grid item md={4} xs={12} className={`${classes.fieldBlock} ${recipientError ? classes.errorMsg : ''}`}>
                                <FormControl role="region" aria-label="Selectrecipient" variant="outlined">
                                    <InputLabel className={!state.recipient && classes.elementHidden} htmlFor="recipient_select">Who&rsquo;s This For?</InputLabel>
                                    <NativeSelect
                                        defaultValue=""
                                        className={state.recipient ? `${classes.select} ${classes.recipientSelect}` : classes.select}
                                        value={state.recipient}
                                        disableUnderline
                                        error
                                        onChange={(e) => handleChange(e, 'recipientkey')}
                                        inputProps={{
                                            name: 'recipient',
                                            id: 'recipient_select',
                                            'aria-hidden': 'true',
                                            tabIndex: '-1',
                                        }}
                                    >
                                        <option className={state.recipient && classes.elementHidden} disabled aria-hidden="true" value="">Who&rsquo;s This For?</option>
                                        {
                                            recipients?.map((k, index) => <option key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                        }
                                    </NativeSelect>
                                    {errorShowing('Please choose a recipient')}
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12} className={`${classes.fieldBlock}  ${occasionError ? classes.errorMsg : ''}`}>
                                <FormControl role="region" aria-label="Selectocssian" variant="outlined">
                                    <InputLabel className={!state.occasion && classes.elementHidden} htmlFor="occasion_select">What&rsquo;s the Occasion?</InputLabel>
                                    <NativeSelect
                                        defaultValue=""
                                        className={state.occasion ? `${classes.select} ${classes.recipientSelect}` : classes.select}
                                        value={state.occasion}
                                        disableUnderline
                                        onChange={(e) => handleChange(e, 'occasionKey')}
                                        error
                                        inputProps={{
                                            name: 'occasion',
                                            id: 'occasion_select',
                                            'aria-hidden': 'true',
                                            tabIndex: '-1',
                                        }}
                                    >
                                        <option className={state.occasion && classes.elementHidden} disabled aria-hidden="true" value="">What&rsquo;s the Occasion?</option>
                                        {
                                            occasions?.map((k, index) => <option key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                        }
                                    </NativeSelect>
                                    {errorShowing('Please choose an occasion')}
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12} className={`${classes.fieldBlock} ${priceError ? classes.errorMsg : ''}`}>
                                <FormControl role="region" aria-label="Selectbudget" variant="outlined">
                                    <InputLabel className={!state.price && classes.elementHidden} htmlFor="price_select">What&rsquo;s Your Budget?</InputLabel>
                                    <NativeSelect
                                        defaultValue=""
                                        className={state.price ? `${classes.select} ${classes.recipientSelect}` : classes.select}
                                        value={state.price}
                                        disableUnderline
                                        error
                                        onChange={(e) => handleChange(e, 'priceKey')}
                                        inputProps={{
                                            name: 'price',
                                            id: 'price_select',
                                            'aria-hidden': 'true',
                                            tabIndex: '-1',
                                        }}
                                    >
                                        <option className={state.price && classes.elementHidden} disabled aria-hidden="true" value="">What&rsquo;s Your Budget?</option>
                                        {
                                            prices?.map((k, index) => <option key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                        }
                                    </NativeSelect>
                                    { errorShowing('Please choose a budget')}
                                </FormControl>
                            </Grid>
                            <Grid role="region" aria-label="FindButton" item xs={12} className={classes.findItButton}>
                                <Button onClick={() => handleOnClick()} className={classes.button} variant="contained" color="primary">FIND IT</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
                {/* prefetch image used as BG to improve LCP */}
                <img src={imageUrl} alt="background image" className={classes.lcpImage} width="0" height="0" />
            </div>
            {
                renderFastLineGiftFinder(dataSelector?.product_recommendations)
            }
        </div>
    );
}

GraphqlFoodGiftFinder.propTypes = {
    data: shape({
        facet_occasion: shape({
            occasion: shape({
                value: array.isRequired,
            }),
        }),
        facet_price: shape({
            price: shape({
                value: array.isRequired,
            }),
        }),
        facet_recipient: shape({
            recipient: shape({
                value: array.isRequired,
            }),
        }),
        heading: string.isRequired,
        heading_image: array.isRequired,
        landing_page: shape({
            title: string.isRequired,
            href: string.isRequired,
        }),
    }).isRequired,
    history: object.isRequired,
    track: func.isRequired,
    giftFinderV2FlagEnabled: bool,
    ffIsGFSuggestionsEnabled: bool.isRequired,
    abTest: object,
    isUniversalLander: bool,
    ulGiftFinderCss: object,
    flags: object.isRequired,
};

GraphqlFoodGiftFinder.defaultProps = {
    giftFinderV2FlagEnabled: false,
    abTest: {},
    isUniversalLander: false,
    ulGiftFinderCss: {},
};

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});

const mapStateToProps = (state) => ({
    giftFinderV2FlagEnabled: getFeatureFlag('is-gift-finder-v2-enabled')(state),
    ffIsGFSuggestionsEnabled: getFeatureFlag('is-gift-finder-suggestions-enabled')(state),
    abTest: getActiveABTests(state),
});

export default compose(
    withLDConsumer(),
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(GraphqlFoodGiftFinder);
