/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    Button,
    FormControl,
    InputLabel,
    makeStyles,
    NativeSelect,
    Paper,
    FormHelperText,
    useMediaQuery,
} from '@material-ui/core';
import {
    arrayOf,
    bool, func, object, shape, string,
} from 'prop-types';
import React from 'react';
import { uid } from 'react-uid';
import { ExpandMore } from '@material-ui/icons';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import GiftFinderProductContainer from '../../../../GiftFinderProduct/GiftFinderProductContainer';

const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const useStyles = makeStyles((theme) => ({
    GiftFinderV2Presentation: () => ({
        maxWidth: '1400px',
        margin: '2em auto',
        width: '100%',
        [theme.breakpoints.down(1401)]: {
            padding: '0px 0.75rem',
        },
    }),
    paperBody: {
        padding: '0.75rem',
        borderRadius: '0px',
    },
    giftFinderV2InnerContainer: {
        background: theme.palette.cms?.giftFinderBackgroundColor || '#E8F1ED',
    },
    mainContainer: {
        width: '100%',
        margin: '0 auto',
        padding: '1.5rem 3.063rem',
        [theme.breakpoints.down(801)]: {
            padding: '1.5rem 1.875rem',
        },
        [theme.breakpoints.down(681)]: {
            padding: '2rem 1.5rem',
        },
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '4%',
    },
    fieldBlock: {
        width: '100%',
        flexBasis: '29%',
        position: 'relative',
        [theme.breakpoints.down(768)]: {
            flexBasis: '100%',
            paddingBottom: '1.8em',
        },
        '& .MuiFormControl-root': {
            width: '100%',
        },
        '& label': {
            fontSize: '1rem',
        },
        '& [class*="shrink"]': {
            top: '12px',
            color: theme.palette.giftFinderSelectColor,
        },
        '& .MuiInput-select:focused': {
            background: theme.palette.myAccount?.container?.backgroundColor,
        },
        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },
    },
    select: ({ data }) => ({
        width: '100%',
        minHeight: '37px',
        border: `solid 1px ${theme.palette.colorNeutral70}`,
        background: theme.palette.tiers?.card?.backgroundColor,
        fontSize: '1em',
        [theme.breakpoints.down(920)]: {
            fontSize: '0.9em',
        },
        fontFamily: parseFont(data?.reference?.[0]?.select_field_font),
        '& :focus': {
            background: theme.palette.myAccount?.container?.backgroundColor,
        },
        '& .MuiNativeSelect-select': {
            padding: '0.375rem 0.75rem',
            color: theme.palette.giftFinderSelectColor,
            [theme.breakpoints.down(600)]: {
                padding: '0.938rem 0.875rem',
                margin: '0.375rem 0px 0px',
            },
        },
        '& .MuiNativeSelect-filled': {
            border: `solid 1px ${theme.palette.giftFinderSelectColor} `,
        },
        '& svg': {
            fill: theme.palette.colorNeutral70,
        },
    }),
    button: {
        margin: '1.5rem auto 0',
        width: '100%',
        borderRadius: '0px',
        backgroundColor: theme.palette.primary.main || '#2f2f2f',
        textTransform: 'uppercase',
        fontSize: '1rem',
        fontWeight: '700',
        '&:hover': {
            backgroundColor: theme.palette.primary.main || '#2f2f2f',
        },
        [theme.breakpoints.down(728)]: {
            fontSize: '0.875rem',
        },
        [theme.breakpoints.down(681)]: {
            margin: '0.313rem auto 0',
        },
    },
    helperText: {
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        fontSize: '0.875rem',
        overflowY: 'hidden',
        [theme.breakpoints.down(681)]: {
            fontSize: '0.75rem',
        },
        maxHeight: '0',
        zIndex: '-1',
        top: '100%',
        margin: '0px',
        transitionProperty: 'all',
        transitionDuration: '.5s',
        transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',
        '& svg': {
            fontSize: '0.875rem',
            borderRadius: '50%',
            color: theme.palette.giftFinderErrorColor,
            backgroundColor: theme.palette.white,
            marginRight: '0.25rem',
        },
    },
    findItButton: {
        flexBasis: '29%',
        [theme.breakpoints.down(768)]: {
            flexBasis: '100%',
        },
        '& .MuiButtonBase-root': {
            display: 'block',
        },
        '& .MuiButton-root': {
            padding: '0.375rem',
        },
    },
    errorMsg: {
        '& $helperText': {
            color: theme.palette.giftFinderErrorColor,
            maxHeight: '1.563rem',
            margin: '0px',
            zIndex: 1,
            [theme.breakpoints.down(681)]: {
                maxHeight: '1rem',
            },
        },
        '& $select': {
            color: theme.palette.giftFinderErrorColor,
            borderColor: theme.palette.giftFinderErrorColor,
            '& svg': {
                fill: theme.palette.giftFinderErrorColor,
            },
        },
        '& .MuiInputLabel-formControl': {
            color: theme.palette.giftFinderErrorColor,
        },

    },
    elementHidden: {
        display: 'none',
    },
    nativeSelect: {
        '& .MuiNativeSelect-select': {
            [theme.breakpoints.up(600)]: {
                padding: '1rem 0.75rem 0.125rem !important', // important is added to overwrite parent property
            },
        },
    },
    giftFinderHorizontal: {
        margin: '0 auto',
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: '24px',
        '@media screen and (max-width: 767px)': {
            padding: '0px 24px 30px',
            margin: '0px 12px',
        },
    },
}));

const  GiftFinderV2Presentation = ({
    recipientError,
    states,
    handleChange,
    recipients,
    occasionError,
    occasions,
    handleOnClick,
    priceError,
    prices,
    dataSelector,
}) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 767px)');
    const isGiftSuggestionEnable = useSelector(getFeatureFlag('is-gift-finder-suggestions-enabled'));
    const { whichGiftFinderSuggestion } = useFlags();

    const ffIsGFSuggestionsEnabled = (isGiftSuggestionEnable && whichGiftFinderSuggestion === 'variant' && dataSelector?.has_recommendations);
    const productData = dataSelector?.product_recommendations || [];

    // showing the error message
    const errorShowing = (errorMsg) => (
        <FormHelperText
            aria-hidden="true"
            className={`${classes.helperText} `}
        >
            <CancelOutlinedIcon />{errorMsg}
        </FormHelperText>
    );

    // button text for mobile and desktop
    const showingButtonTitle =  isMobile ? 'FIND YOUR PERFECT GIFT' : 'FIND YOUR GIFT';

    return (
        <div className={`${classes.GiftFinderV2Presentation} giftFinder`}>
            <Paper elevation={3} className={classes.paperBody}>
                <div className={classes.giftFinderV2InnerContainer}>
                    <div className={classes.mainContainer}>
                        <div className={`${classes.fieldBlock}  ${occasionError ? classes.errorMsg : ''}`}>
                            <FormControl role="region" aria-label="Selectocssian" variant="outlined">
                                <InputLabel className={!states.occasion && classes.elementHidden} htmlFor="occasion_select">What&rsquo;s the Occasion?</InputLabel>
                                <NativeSelect
                                    defaultValue=""
                                    className={`${classes.select} ${states.occasion ? classes.nativeSelect : ''}`}
                                    value={states.occasion}
                                    disableUnderline
                                    onChange={(e) => handleChange(e, 'occasionKey')}
                                    error
                                    inputProps={{
                                        name: 'occasion',
                                        id: 'occasion_select',
                                        'aria-hidden': 'true',
                                        tabIndex: '-1',
                                    }}
                                    IconComponent={ExpandMore}
                                >
                                    <option className={states.occasion && classes.elementHidden} disabled aria-hidden="true" value="">What&rsquo;s the Occasion?</option>
                                    {
                                        occasions?.map((k, index) => <option className={classes.colorBack} key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                    }
                                </NativeSelect>
                                {errorShowing('Please choose an occasion')}
                            </FormControl>
                        </div>
                        <div className={`${classes.fieldBlock} ${recipientError ? classes.errorMsg : ''}`}>
                            <FormControl role="region"  aria-label="Selectrecipient" variant="outlined">
                                <InputLabel className={!states.recipient && classes.elementHidden} htmlFor="recipient_select">Who&rsquo;s This For?</InputLabel>
                                <NativeSelect
                                    defaultValue=""
                                    className={`${classes.select} ${states.recipient ? classes.nativeSelect : ''}`}
                                    value={states.recipient}
                                    disableUnderline
                                    error
                                    onChange={(e) => handleChange(e, 'recipientkey')}
                                    inputProps={{
                                        name: 'recipient',
                                        id: 'recipient_select',
                                        'aria-hidden': 'true',
                                        tabIndex: '-1',
                                    }}
                                    IconComponent={ExpandMore}
                                >
                                    <option className={states.recipient && classes.elementHidden} disabled aria-hidden="true" value="">Who&rsquo;s This For?</option>
                                    {
                                        recipients?.map((k, index) => <option key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                    }
                                </NativeSelect>
                                {errorShowing('Please choose a recipient')}
                            </FormControl>
                        </div>
                        <div className={`${classes.fieldBlock} ${priceError ? classes.errorMsg : ''}`}>
                            <FormControl role="region" aria-label="Selectbudget" variant="outlined">
                                <InputLabel className={!states.price && classes.elementHidden} htmlFor="price_select">What&rsquo;s Your Budget?</InputLabel>
                                <NativeSelect
                                    defaultValue=""
                                    className={`${classes.select} ${states.price ? classes.nativeSelect : ''}`}
                                    value={states.price}
                                    disableUnderline
                                    error
                                    onChange={(e) => handleChange(e, 'priceKey')}
                                    inputProps={{
                                        name: 'price',
                                        id: 'price_select',
                                        'aria-hidden': 'true',
                                        tabIndex: '-1',
                                    }}
                                    IconComponent={ExpandMore}
                                >
                                    <option className={states.price && classes.elementHidden} disabled aria-hidden="true" value="">What&rsquo;s Your Budget?</option>
                                    {
                                        prices?.map((k, index) => <option key={uid(k, index)} aria-hidden="true" value={k.value}>{k.key}</option>)
                                    }
                                </NativeSelect>
                                { errorShowing('Please choose a budget')}
                            </FormControl>
                        </div>
                        <div role="region" aria-label="FindButton" className={classes.findItButton}>
                            <Button
                                onClick={() => handleOnClick()}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                            >
                                {showingButtonTitle} &#x276f;
                            </Button>
                        </div>
                    </div>
                </div>
            </Paper>
            {ffIsGFSuggestionsEnabled && (
                <div
                    className={classes.giftFinderHorizontal}
                    style={{
                        backgroundColor: productData?.styling?.module_background_color?.color,
                    }}
                >
                    <GiftFinderProductContainer productData={productData} />
                </div>
            )}
        </div>
    );
};

GiftFinderV2Presentation.propTypes = {
    recipientError: bool,
    states: object,
    handleChange: func,
    recipients: arrayOf(shape({
        key: string.isRequired,
        value: string.isRequired,
    })).isRequired,
    occasionError: bool,
    occasions: arrayOf(shape({
        key: string.isRequired,
        value: string.isRequired,
    })).isRequired,
    handleOnClick: func.isRequired,
    priceError: bool,
    prices: arrayOf(shape({
        key: string.isRequired,
        value: string.isRequired,
    })).isRequired,
    dataSelector: object,
};

GiftFinderV2Presentation.defaultProps = {
    recipientError: false,
    states: {},
    handleChange: () => {},
    occasionError: false,
    priceError: false,
    dataSelector: {},
};

export default GiftFinderV2Presentation;
